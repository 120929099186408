import React from 'react';
import { Resource } from 'store/resource/types';
import { NoResults } from 'components/no-result';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { FlexContent } from 'pages/resources/content/flex-content';
import { InifiniteContent } from 'pages/resources/content/infinite-content';

export interface ContentProps {
  resources: Array<Resource>;
}

export const Content = ({ resources }: ContentProps): JSX.Element => {
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const { floorPlanRedirection } = useSearchParams();

  const getContent = () => {
    if (isCompactUiEnabled) {
      return <InifiniteContent />;
    }
    return <FlexContent />;
  };

  return resources?.length > 0 || +floorPlanRedirection ? getContent() : <NoResults />;
};
