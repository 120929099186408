export enum AdapterTypeEnum {
  STANDARD = 'STANDARD',
  NATIVE = 'NATIVE',
  CUSTOM = 'CUSTOM',
  TEST = 'TEST',
}

export enum PaymentReceiversEnum {
  MINDBODY = 'Mindbody',
  TEST = 'Test',
}

export enum PaymentGatewayTypes {
  STRIPE = 'stripe',
  TEST = 'test',
  STRIPE_INTENTS = 'stripe_payment_intents',
}

export enum GeographiesEnum {
  EU = 'EU',
  NA = 'NA',
  GLOBAL = 'GLOBAL',
}

export enum PartnerTypesEnum {
  DEVELOPER = 'DEVELOPER',
  AFFILIATE = 'AFFILIATE',
}

export enum MediaOwnerTypeEnum {
  APP = 'App',
  VENDOR = 'Vendor',
}

export enum MediaMimeTypeEnum {
  JPEG_VIDEO = 'JPEG_VIDEO',
  JPEG_IMG = 'JPEG_IMG',
  PNG = 'PNG',
  SVG = 'SVG',
  FLASH = 'FLASH',
  MPEG_4 = 'MPEG_4',
}

export enum VerticalTypeEnum {
  BOOKING_GENERAL = 'BOOKING_GENERAL',
  BOOKING_WELLNESS = 'BOOKING_WELLNESS',
  SERVICE_BOOKING = 'SERVICE_BOOKING',
}

export enum UtilityButtonTypeEnum {
  WEBVIEW = 'WEBVIEW',
  NATIVE = 'NATIVE',
  MICROFRONTEND = 'MICROFRONTEND',
  NATIVELINK = 'NATIVELINK',
  DEEPLINK = 'DEEPLINK',
}

export enum TraitOwnerTypeEnum {
  Building = 'Building',
  BuildingCompany = 'BuildingCompany',
  Company = 'Company',
  User = 'User',
}

export enum OfferRuleTypesEnum {
  ELIGIBILITY = 'ELIGIBILITY',
  DISCOUNT = 'DISCOUNT',
}

export enum AmountTypesEnum {
  DOLLAR = 'DOLLAR',
  PERCENTAGE = 'PERCENTAGE',
  ITEM = 'ITEM',
}

export enum TargetEntityTypesEnum {
  ORDER = 'ORDER',
  MENU = 'MENU',
  MENU_CATEGORY = 'MENU_CATEGORY',
  MENU_ITEM = 'MENU_ITEM',
}

export enum ParentTypesEnum {
  COMPANY = 'Company',
  BUILDING = 'Building',
}

export enum OwnerTypesEnum {
  COMPANY = 'company',
  BUILDING = 'building',
}

export enum ClaimsResetIntervalEnum {
  PROMOTION = 'PROMOTION',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum SubscriberTypeEnum {
  BUILDING = 'building',
  COMPANY = 'company',
  USER = 'user',
  ROLE = 'role',
}

export enum AudienceTypeEnum {
  BUILDING = 'BUILDING',
  COMPANY = 'COMPANY',
  USER = 'USER',
  ROLE = 'ROLE',
}

export enum CategoryTypeEnum {
  Amenities = 'Amenities',
  AudienceContent = 'AudienceContent',
  Events = 'Events',
  Perks = 'Perks',
  Neighborhoods = 'Neighborhoods',
  SecondaryContent = 'SecondaryContent',
  Categories = 'Categories',
}

export enum CompanyParentTypeEnum {
  DEVELOPER = 'Developer',
  VENDOR = 'Vendor',
  OTHER = 'Other',
  MULTIPLE = 'Multiple',
}

export enum ServiceRequestStatusEnum {
  NEW = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2,
  REJECTED = 3,
  CANCELLED = 4,
  ASSIGNED_TO_BUILDING = 5,
}

export enum ServiceRequestIssueTypeEnum {
  UNSPECIFIED = 0,
  UNLISTED = 1,
  MORE_CLEANING = 2,
  MORE_SECURITY = 3,
  BIN = 4,
  BIN_GARBAGE = 5,
  BIN_RECYCLE = 6,
  CARPET_SHAMPOO = 7,
  DUMPSTER = 8,
  DAY_SERVICES = 9,
  DIRECTORY_PLATES = 10,
  DOOR_SERVICE = 11,
  EWASTE_REMOVAL = 12,
  ELECTRICAL = 13,
  LIFT = 14,
  ENGINEER = 15,
  EXTERMINATING = 16,
  FURNITURE = 17,
  HANDYMAN = 18,
  HVAC_MAINTENANCE = 19,
  HVAC_OVERTIME = 20,
  ISOLATE = 21,
  KEY_LOCK = 22,
  LEAKS = 23,
  LIGHTING = 24,
  LOCKSMITH = 25,
  MARBLE = 26,
  METAL = 27,
  NIGHT_SERVICES = 28,
  PARKING = 29,
  ID_CANCELLATION = 30,
  ID_NEW = 31,
  ID_REPLACEMENT = 32,
  PLUMBING = 33,
  PORTER = 34,
  RUBBISH = 35,
  SECURITY = 36,
  SHUTDOWN = 37,
  SIGNAGE = 38,
  SUPPLY = 39,
  TOO_COLD = 40,
  TOO_HOT = 41,
  UTILITY_BILLBACK = 42,
  WINDOWS_EXT = 43,
  WINDOWS_INT = 44,
  WOOD = 45,
}

export enum ServiceBookingAdaptersEnum {
  MindbodyProviderAdapter = 'MindbodyProviderAdapter',
  NativeProviderAdapter = 'NativeProviderAdapter',
  ServiceBookingStandardAdapter = 'ServiceBookingStandardAdapter',
  NativeTestAdapter = 'NativeTestAdapter',
}

export enum ServiceBookingTypes {
  BOOKING_GENERAL = 'BOOKING_GENERAL',
  BOOKING_WELLNESS = 'BOOKING_WELLNESS',
  SERVICE_BOOKING = 'SERVICE_BOOKING',
  GROUP = 'GROUP',
  APPOINTMENT = 'APPOINTMENT',
}

export enum KeywordsEnum {
  ACCESSIBILITY = 'Accessibility',
  WORKPLACE_SERVICES = 'Workplace Services',
  ENTERTAINMENT = 'Entertainment',
  MOBILITY = 'Mobility',
  FOOD_AND_BEVERAGE = 'Food & Beverage',
  SECURITY = 'Security',
  SUSTAINABILITY = 'Sustainability',
  WELLNESS = 'Wellness',
}

export enum ResourceBookingEnum {
  TestAdapter = 'TestAdapter',
  NativeAdapter = 'NativeAdapter',
  AngusAdapter = 'AngusAdapter',
  StandardAdapter = 'ResourceBookingStandardAdapter',
  NexudusAdapter = 'NexudusAdapter',
  PrismAdapter = 'PrismResourceBookingAdapter',
}

export enum TraitConfigTypeEnum {
  WebviewUtilityButtonConfig = 'WebviewUtilityButtonConfig',
  NativeUtilityButtonConfig = 'NativeUtilityButtonConfig',
  MicroFrontendUtilityButtonConfig = 'MicroFrontendUtilityButtonConfig',
  OpenpathMACConfig = 'OpenpathMACConfig',
  KastleMACConfig = 'KastleMACConfig',
  STidMACConfig = 'STidMACConfig',
  ServiceProviderConfig = 'ServiceProviderConfig',
  AngusVisitorRegistrationConfig = 'AngusVisitorRegistrationConfig',
  TestVisitorRegistrationConfig = 'TestVisitorRegistrationConfig',
  NativeVisitorRegistrationConfig = 'NativeVisitorRegistrationConfig',
  StandardVisitorRegistrationConfig = 'StandardVisitorRegistrationConfig',
  OrderingCompanyConfig = 'OrderingCompanyConfig',
  MetadataConfig = 'MetadataConfig',
  AngusWorkOrderConfig = 'AngusWorkOrderConfig',
  TestWorkOrderConfig = 'TestWorkOrderConfig',
  StandardWorkOrderConfig = 'StandardWorkOrderConfig',
  MindbodyConfig = 'MindbodyProviderAdapter',
  TestMobileAccessCredentialingConfig = 'TestMobileAccessCredentialingConfig',
  HIDMobileAccessCredentialingConfig = 'HIDMobileAccessCredentialingConfig',
  BluboxMobileAccessCredentialingConfig = 'BluboxMobileAccessCredentialingConfig',
  StandardMobileAccessCredentialingConfig = 'StandardMobileAccessCredentialingConfig',
  BluboxACSConfig = 'BluboxACSConfig',
  OpenpathACSConfig = 'OpenpathACSConfig',
  TestACSConfig = 'TestACSConfig',
  StandardAccessControlConfig = 'StandardAccessControlConfig',
  CloudgateVisitorRegistrationConfig = 'CloudgateVisitorRegistrationConfig',
  AngusResourceBookingConfig = 'AngusResourceBookingConfig',
  NativeResourceBookingConfig = 'NativeResourceBookingConfig',
  TestResourceBookingConfig = 'TestResourceBookingConfig',
  NexudusResourceBookingConfig = 'NexudusResourceBookingConfig',
  OrigoMobileAccessCredentialingConfig = 'OrigoMobileAccessCredentialingConfig',
  StandardResourceBookingConfig = 'StandardResourceBookingConfig',
  WendellaTicketingConfig = 'WendellaTicketingConfig',
  TestEmergencyCommsConfig = 'TestEmergencyCommsConfig',
  StandardShuttleTrackingConfig = 'StandardShuttleTrackingConfig',
  TestShuttleTrackingConfig = 'TestShuttleTrackingConfig',
  VerticalConfig = 'VerticalConfig',
  EmergencyCommsStandardAdapter = 'EmergencyCommsStandardAdapter',
  PassengerShuttleTrackingConfig = 'PassengerShuttleTrackingConfig',
  IntelliCommandSmartBuildingConfig = 'IntelliCommandSmartBuildingConfig',
  EnvoyVisitorRegistrationConfig = 'EnvoyVisitorRegistrationConfig',
  ServiceRequestNativeConfig = 'ServiceRequestNativeConfig',
  BluepointVisitorRegistrationConfig = 'BluepointVisitorRegistrationConfig',
  SodexoWalletConfig = 'SodexoWalletConfig',
  SodexoOrderAheadConfig = 'SodexoOrderAheadConfig',
}
