import { useSelector } from 'react-redux';

import { useMemo } from 'react';
import { bookingsState } from 'store/bookings/selectors';

import { BookingStatus } from 'store/bookings/types';
import { selectTransactions, selectTransactionsLoading } from 'store/transactions/selectors';
import { TransactionDetails } from 'store/transactions/types';

export const useUpcomingBookings = () => {
  const upcomingBookings = useSelector(bookingsState)
    .bookings.filter(
      booking =>
        booking.status !== BookingStatus.CANCELED &&
        booking.status !== BookingStatus.DENIED_AVAILABLE &&
        booking.status !== BookingStatus.DENIED_UNAVAILABLE,
    )
    .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
  const transactions = useSelector(selectTransactions)?.filter(
    transaction =>
      transaction.details.status !== BookingStatus.CANCELED &&
      transaction.details.status !== BookingStatus.DENIED_AVAILABLE &&
      transaction.details.status !== BookingStatus.DENIED_UNAVAILABLE,
  );
  const formatTransactions: TransactionDetails[] = useMemo(
    () =>
      transactions?.reduce((acc, transaction) => {
        acc.push(transaction);

        return acc.sort((a, b) => new Date(a.details.start_at).getTime() - new Date(b.details.start_at).getTime());
      }, []),
    [transactions],
  );
  const getTransactionsStatus = useSelector(selectTransactionsLoading);
  const getUpcomingBookingsStatus = useSelector(bookingsState).loading;

  return { upcomingBookings, getUpcomingBookingsStatus, formatTransactions, getTransactionsStatus };
};
