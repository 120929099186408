import { ResourcesState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const resourcesState = (state: RootState): ResourcesState => state.resources;

export const selectResources = createSelector([resourcesState], resources => resources.resources);

export const selectResourcesAmount = createSelector([resourcesState], resources => resources?.resources?.length);

export const selectGetResourcesLoadingStatus = createSelector([resourcesState], resources => resources.loading);

export const selectInfiniteResources = createSelector([resourcesState], resources => resources.infiniteResources);

export const selectGetInfiniteResourcesStatus = createSelector(
  [resourcesState],
  resources => resources.getInfiniteResources,
);

export const selectInfiniteResourcesTotal = createSelector([resourcesState], resources => resources.total);
