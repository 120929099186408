import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import styled, { css } from 'styled-components';

export const skeletonAnimation = () => css`
  background: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground};
  background: ${({ theme }) =>
    `linear-gradient(110deg, ${theme?.colors?.greys?.adminGreyMedium} 8%, ${theme?.colors?.greys?.adminGreyLightAlt} 18%, ${theme?.colors?.greys?.adminGreyMedium} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const Container = styled.div`
  padding: 28px 16px;
  max-width: 100vw;
  .desktop {
    display: none;
  }
  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    .desktop {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }
`;

export const Square = styled.div`
  width: 30px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const Bar = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const DesktopBar = styled.div`
  width: 286px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const CounterBar = styled.div`
  margin-top: 25px;
  width: 98px;
  height: 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};

  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    margin-top: 0;
  }
`;

export const TopCounterBar = styled.div`
  width: 98px;
  height: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};

  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    margin-bottom: 0;
  }
`;

export const ResourceTile = styled.div<{ isDesktop?: boolean }>`
  width: 120px;
  height: 78px;
  ${({ isDesktop }) => isDesktop && 'width: 197px; height: 128px;'}
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const ResourceTitle = styled.div<{ isDesktop?: boolean }>`
  width: 132px;
  height: 15px;
  ${({ isDesktop }) => isDesktop && 'width: 185px; height: 24px;'}
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const ResourceSubtitle = styled.div<{ isDesktop?: boolean }>`
  width: 98px;
  height: 15px;
  ${({ isDesktop }) => isDesktop && 'width: 161px; height: 24px;'}
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const FlexContainer = styled.div<{
  isColumnDirection?: boolean;
  gap: number;
  isWidth?: boolean;
  isWrap?: boolean;
  flex?: number;
}>`
  display: flex;
  ${({ isWidth }) => isWidth && 'width: 100%'};
  flex-direction: ${({ isColumnDirection }) => (isColumnDirection ? 'column' : 'row')};
  gap: ${({ gap }) => gap}px;
  ${({ isWrap }) => isWrap && 'flex-wrap: wrap'};
  ${({ flex }) => flex && `flex: ${flex}`};
  position: relative;
`;
