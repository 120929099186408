import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { QUICK_CHECKOUT_BREAK_POINT } from 'shared/consts';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  margin: 52px 32px 30px 32px;
  display: grid;
  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    margin: 39px 16px 20px 16px;
  }
`;

export const QuickCheckoutContent = styled.div`
  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    .text-container {
      flex-basis: 75%;
      flex-grow: 0;
    }

    .subtitle-text {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-inline-box;
    }
  }
`;

export const StyledTitleBlock = styled(TitleBlock)``;

export const StyledPriceBlock = styled.div`
  margin-top: 15px;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const EditButton = styled.div`
  font-weight: 500;
  padding: 5px;
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
