import { skipToken } from '@reduxjs/toolkit/query';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useSelector } from 'react-redux';
import { selectCurrentAppInstanceConfigUuid, selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { resourcesApi } from 'store/resources/api';
import { ResourceType } from 'store/resources/types';

export type UseGetResourcesTypesQueryType = typeof resourcesApi.useGetResourcesTypesQuery;
export type UseGetResourcesTypesQueryResultType = ReturnType<UseGetResourcesTypesQueryType> & { data?: ResourceType[] };

export function useGetCurrentLocationResourcesTypesQuery(
  options: Parameters<UseGetResourcesTypesQueryType>[1] = {},
): UseGetResourcesTypesQueryResultType {
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const isFilteringEnabled = useFilteringEnabled();
  const { skip, ...rest } = options;
  const doSkip = !isFilteringEnabled || !currentLocationId || !currentAppInstanceConfigUuid || skip === true;
  return resourcesApi.useGetResourcesTypesQuery(
    doSkip ? skipToken : { locationId: currentLocationId, appInstanceConfigUuid: currentAppInstanceConfigUuid },
    rest,
  );
}
