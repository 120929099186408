import styled from 'styled-components';

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  font: 500 28px/32px ${({ theme }) => theme.fonts.join()};
  padding: 10px 0 35px 52px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
`;

export const StyledBackLink = styled.div`
  padding-left: 52px;
`;

export const Container = styled.div`
  padding-top: 45px;
`;
