import { Item } from 'components/time-selector/time-selector.interface';

export const formatTimeLabel = (date: Date, locale: string, isEndTime = false) => {
  const midnightOptions = date.getHours() === 0 && date.getMinutes() === 0 && isEndTime ? { hour12: false } : {};

  return date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    ...midnightOptions,
  });
};

export const formatStartEndTimeItemsArray = (
  timeArray: Array<string>,
  dateString: string,
  locale: string,
  isEndTime = false,
): Array<Item> => {
  return timeArray.map(timeLabel => {
    const startTimeHourMinute = timeLabel.split(':');
    const dateValue = new Date(dateString);
    dateValue.setHours(+startTimeHourMinute[0]);
    dateValue.setMinutes(+startTimeHourMinute[1]);

    const formattedTimeLabel = formatTimeLabel(dateValue, locale, isEndTime);

    return { label: formattedTimeLabel, value: dateValue };
  });
};
