import React, { ChangeEvent } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RadioForm, RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { CheckboxHqo } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';

import { LocationsListContainer, CheckboxFormContainer, StyledCheckboxHqo } from './styles';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { useIntl } from 'react-intl';

export interface CheckboxOption {
  label: string;
  subtitle: string;
  value: string;
  selected: boolean;
}

interface SelectLocationModalContentProps {
  onRadioButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
  onLocationsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedLocationName: string;
  options: CheckboxOption[] | RadioOption[];
  selectAllChecked: boolean;
  onSelectAllChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SelectLocationModalContent = ({
  onRadioButtonClick,
  onLocationsChange,
  selectedLocationName,
  options,
  selectAllChecked,
  onSelectAllChange,
}: SelectLocationModalContentProps): JSX.Element => {
  useToggleNativeHeader();
  useToggleSwipeGestures();
  const intl = useIntl();

  const { resourceBookingPortfolioBased } = useFlags();

  return (
    <LocationsListContainer>
      {resourceBookingPortfolioBased ? (
        <CheckboxFormContainer className="locations-list" data-testid="checkbox-form-test-id">
          <div className="button-container">
            <StyledCheckboxHqo
              label={intl.formatMessage({ id: 'select_all_locations' })}
              onChange={onSelectAllChange}
              value="all"
              checked={selectAllChecked}
              name="all"
            />
          </div>
          {options.map((checkbox: CheckboxOption) => (
            <div key={checkbox.value} data-testid={`${checkbox.value}-test-id`} className="button-container">
              <CheckboxHqo
                label={checkbox.label}
                onChange={onLocationsChange}
                value={checkbox.value}
                checked={checkbox.selected}
                name={checkbox.value}
                subtitle={checkbox.subtitle}
              />
            </div>
          ))}
        </CheckboxFormContainer>
      ) : (
        <RadioForm
          className="locations-list"
          options={options as RadioOption[]}
          onPress={onRadioButtonClick}
          selected={selectedLocationName}
          isExpandedTouchArea
          disabled={false}
        />
      )}
    </LocationsListContainer>
  );
};
