import React from 'react';

import { Router } from 'components/router';
import { useViewParams } from 'hooks/use-view-params.hook';
import { useAnalytics } from 'hooks/use-analytics.hook';
import { Skeleton } from 'components/skeleton';
import { useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useDispatch, useSelector } from 'react-redux';
import { getBuilding } from 'store/building/actions';
import { Building } from 'store/building/types';
import { InitHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { getBuildingTheme } from 'store/theme/actions';
import { BuildingTheme } from 'store/theme/types';
import { getCurrentUser } from 'store/user/actions';
import { User } from 'store/user/types';
import { useAppLoading } from 'components/app/use-app-loading.hook';
import { selectBuildingStatus } from 'store/building/selectors';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useBuilding } from 'hooks/use-building.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setFeatureFlags } from 'store/featureFlags/actions';
import { ACTION_STATUSES } from 'shared/consts';
import { useGetTheme } from 'hooks/use-get-theme.hook';
import { useDefaultAppInstanceConfigUuid } from 'hooks/use-default-app-instance-config-uuid.hook';
import { useDefaultLocationId } from 'hooks/use-default-location-id.hook';
import { useSyncAccessTokenWithHostApp } from 'hooks/use-sync-access-token-with-hostapp.hook';
import useSyncKioskWithHostApp from 'store/kiosk/hooks/use-sync-kiosk-with-host-app';

export const App: React.FC = (): JSX.Element => {
  const { loading } = useAppLoading();
  const dispatch = useDispatch();
  useAnalytics();
  useBuildingTheme();
  useDefaultAppInstanceConfigUuid();
  useDefaultLocationId();
  const params = useViewParams();
  const buildingStatus = useSelector(selectBuildingStatus);
  const theme = useGetTheme();
  const building = useBuilding();
  const lDFlags = useFlags();
  dispatch(setFeatureFlags(lDFlags));

  const buildingStatusFinished =
    buildingStatus === ACTION_STATUSES.FULFILLED || buildingStatus === ACTION_STATUSES.REJECTED;

  const hasBuilding = building || params.buildingUuid;

  useSyncAccessTokenWithHostApp();
  useMiniappSdkEventHandler(
    'initResponse',
    (data: InitHandlerPayload) => {
      dispatch(getCurrentUser.success({ user: data?.user as unknown as User }));
      dispatch(getBuilding.success(data?.building as unknown as Building));
      dispatch(getBuildingTheme.success(data?.theme as unknown as BuildingTheme));
    },
    [dispatch],
  );

  useSyncKioskWithHostApp();

  if (loading && theme && (buildingStatusFinished || !hasBuilding)) {
    return <Skeleton />;
  }

  return <Router />;
};
