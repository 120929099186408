import { LDUser } from 'launchdarkly-js-client-sdk';
import { VERTICAL_NAME } from '../shared/consts/const';
import { useCurrentUser } from './use-current-user.hook';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { configSelector } from 'store/config/selectors';
import { selectBuilding } from 'store/building/selectors';

interface WebTrackingData {
  currentUser: LDUser;
  buildingUuid: string;
  appUuid: string;
  clientApiUrl: string;
  authToken: string;
}

export const useWebTrackingAnalytics = (): WebTrackingData | null => {
  const building = useSelector(selectBuilding);
  const [user] = useCurrentUser();
  const { apiUrl: clientApiUrl, authToken } = useSelector(configSelector);

  return useMemo(() => {
    if (!building || !user) {
      return null;
    }
    return {
      currentUser: { key: user.uuid, email: user.email },
      buildingUuid: building.uuid,
      appUuid: VERTICAL_NAME,
      clientApiUrl,
      authToken,
    };
  }, [building, user, clientApiUrl, authToken]);
};
