import { ACTION_STATUSES, FontLocation, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { ThemeLocationFont, useThemeFont } from 'hooks/use-theme-font.hook';
import { getResources } from 'store/resources/actions';
import {
  selectAppInstanceConfigs,
  selectAppInstanceConfigsStatus,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { selectResources } from 'store/resources/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AppInstanceConfig } from 'store/app-instance-configs/types';
import { Resource } from 'store/resource/types';
import { TransactionDetails } from 'store/transactions/types';
import { getTransactions } from 'store/transactions/actions';
import { selectPatchTransactionsStatus, selectTransactions } from 'store/transactions/selectors';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useLoadingHandler } from './loading-handler.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { resetResource } from 'store/resource/actions';
import { useCurrentAdapterName } from 'hooks/use-current-adapter-name';
import { useCurrentLocation } from 'hooks/use-current-location.hook';
import { track } from '@hqo/web-tracking';
import { selectIsHeaderSet } from 'store/header-state/selectors';
import { setHeaderAction } from 'store/header-state/actions';
import { resetAddOnsState } from 'store/add-ons/actions';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { useTermsAndConditionsVisibility } from 'hooks/use-terms-and-conditions-visibility.hook';
import { resetResourceAvailableDates } from 'store/resource-available-dates/actions';
import { resetResourceTimeRanges } from 'store/resource-time-ranges/actions';

interface UseLandingPageReturnValues {
  isDataLoading: boolean;
  transactions: TransactionDetails[];
  availableNowResources: Resource[];
  isMobileDevice: boolean;
  themeFontTitle: ThemeLocationFont;
  appInstanceConfigs: AppInstanceConfig[];
}

export const useLandingPage = (): UseLandingPageReturnValues => {
  useTermsAndConditionsVisibility();
  const isMobileDevice = useIsSmallViewportWidth();
  const { ownerUuid, ownerType } = useOwnerParams();

  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const dispatch = useDispatch();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const appInstanceConfigsStatus = useSelector(selectAppInstanceConfigsStatus);
  const transactions = useSelector(selectTransactions);
  const availableNowResources = useSelector(selectResources);
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);
  const client = useMiniappSdk();
  const currentLocationName = useCurrentLocation();
  const currentAdapterName = useCurrentAdapterName();
  const isHeaderSet = useSelector(selectIsHeaderSet);
  const selectedAddOns = useSelector(selectAllAddOns);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const { hideResourceBookingAvailableNow: isAvailableNowFeatureHidden } = useFlags();
  const isDataLoading = useLoadingHandler();

  const isAvailableNowEnabled = useMemo(() => {
    if (appInstanceConfigsStatus === ACTION_STATUSES.FULFILLED && currentAppInstanceConfigUuid) {
      return (
        !isAvailableNowFeatureHidden &&
        appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid)?.config.available_now_enabled
      );
    }
    return false;
  }, [isAvailableNowFeatureHidden, appInstanceConfigs, currentAppInstanceConfigUuid, appInstanceConfigsStatus]);

  useEffect(() => {
    if (!isHeaderSet && patchTransactionsStatus !== ACTION_STATUSES.PENDING && !isDataLoading) {
      dispatch(setHeaderAction());
      client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
      client?.header.showHeader();
      client?.swipe.disableBackSwipe();
    }
  }, [isHeaderSet, patchTransactionsStatus, isDataLoading, client, dispatch]);

  useEffect(() => {
    dispatch(resetResourceAvailableDates());
    dispatch(resetResource());
    dispatch(resetResourceTimeRanges());
  }, []);

  useEffect(() => {
    if (selectedAddOns?.length) {
      dispatch(resetAddOnsState());
    }
  }, [dispatch, selectedAddOns]);

  useEffect(() => {
    if (appInstanceConfigsStatus === ACTION_STATUSES.FULFILLED && currentLocationId) {
      if (patchTransactionsStatus !== ACTION_STATUSES.PENDING) {
        dispatch(getTransactions.request({}));
      }

      if (isAvailableNowEnabled) {
        dispatch(getResources.request({ ownerType, ownerUuid, filterParams: { availableNow: 'true' } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appInstanceConfigsStatus,
    dispatch,
    ownerType,
    ownerUuid,
    currentLocationId,
    isAvailableNowFeatureHidden,
    currentAppInstanceConfigUuid,
    // patchTransactionsStatus, // Removed - breaks cancellation of bookings and shows other bookings are being cancelled
    isAvailableNowEnabled,
  ]);

  useEffect(() => {
    track(
      TRACK_EVENT_NAMES.RESOURCE_BOOKING_LANDING_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        service_provider_name: currentAdapterName,
        location_name: currentLocationName,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  return {
    isDataLoading,
    transactions,
    availableNowResources,
    isMobileDevice,
    themeFontTitle,
    appInstanceConfigs,
  };
};
