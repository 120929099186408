import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';

interface TermsAndConditionsResponse {
  isTermsAndConditionsEnabled: boolean;
  termsAndConditionsText: string;
}

interface UseAppInstanceConfigFeatureReturnValues {
  isAvailableNowEnabled: boolean;
  isPresetBookingWindowsEnabled: boolean;
  termsAndConditionsValues: TermsAndConditionsResponse;
  isMultiDayBookingEnabled: boolean;
}

export const useAppInstanceConfigFeature = (): UseAppInstanceConfigFeatureReturnValues => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const {
    showResourceBookingTermsAndConditions,
    showResourceBookingFullDayToggle,
    resourceBookingEnableMultiDayFeature,
  } = useFlags();
  const { pathname } = useLocation();

  const currentAppInstanceConfig = appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid);

  const isAvailableNowEnabled = useMemo((): boolean => {
    return currentAppInstanceConfig?.config?.available_now_enabled;
  }, [currentAppInstanceConfig]);

  const isPresetBookingWindowsEnabled = useMemo((): boolean => {
    if (pathname.includes('/floorplan/')) {
      return (
        appInstanceConfigs.find(appInstanceConfig => appInstanceConfig.config?.preset_booking_windows_enabled) &&
        showResourceBookingFullDayToggle
      );
    }
    return currentAppInstanceConfig?.config?.preset_booking_windows_enabled && showResourceBookingFullDayToggle;
  }, [appInstanceConfigs, currentAppInstanceConfig, showResourceBookingFullDayToggle]);

  const termsAndConditionsValues = useMemo((): TermsAndConditionsResponse => {
    return {
      isTermsAndConditionsEnabled:
        currentAppInstanceConfig?.config?.terms_and_conditions_enabled && showResourceBookingTermsAndConditions,
      termsAndConditionsText: currentAppInstanceConfig?.terms_and_conditions,
    };
  }, [currentAppInstanceConfig, showResourceBookingTermsAndConditions]);

  const isMultiDayBookingEnabled = useMemo((): boolean => {
    return currentAppInstanceConfig?.config?.multi_day_booking_enabled && resourceBookingEnableMultiDayFeature;
  }, [currentAppInstanceConfig, resourceBookingEnableMultiDayFeature]);

  return {
    isAvailableNowEnabled,
    isPresetBookingWindowsEnabled,
    termsAndConditionsValues,
    isMultiDayBookingEnabled,
  };
};
