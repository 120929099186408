import React, { useCallback } from 'react';
import { ResourceItem } from 'components/resources-list-item';
import { useSelector, useDispatch } from 'react-redux';
import { resourcesState } from 'store/resources/selectors';
import { RESOURCE_PATH } from 'shared/consts';
import { push } from 'store/router/actions';
import { useLocation } from 'react-router-dom';
import { resetResource } from 'store/resource/actions';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getResourceBuilding } from 'utils/getResourceBuilding';

export const FlexContent = (): JSX.Element => {
  const { resourceBookingPortfolioBased } = useFlags();
  const { resources } = useSelector(resourcesState);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);

  const onResourceClick = useCallback(
    (resourceUuid: any) => {
      dispatch(resetResource());
      dispatch(push(`${RESOURCE_PATH}/${resourceUuid}${search}`));
    },
    [dispatch, search],
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {resources?.map(resource => (
        <ResourceItem
          key={resource.uuid}
          uuid={resource.uuid}
          title={resource.name}
          subtitle={getResourceBuilding(resource, appInstanceConfigs, resourceBookingPortfolioBased)}
          capacity={+resource.capacity}
          image_url={resource.image_url}
          onClick={onResourceClick}
        />
      ))}
    </>
  );
};
