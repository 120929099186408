import { useMemo } from 'react';
import { DefaultFilterEnum } from 'shared/consts';
import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';
import { useSelector } from 'react-redux';

interface ExchangeAdapterVisibility {
  defaultFilter: DefaultFilterEnum;
  isCompactUiEnabled: boolean;
}

export const useExchangeAdapterHandling = (): ExchangeAdapterVisibility => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);

  return useMemo<ExchangeAdapterVisibility>(() => {
    const currentAppInstanceConfig = appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid);

    return {
      defaultFilter: currentAppInstanceConfig?.config?.default_filter,
      isCompactUiEnabled: currentAppInstanceConfig?.config?.compact_ui_enabled,
    };
  }, [appInstanceConfigs, currentAppInstanceConfigUuid]);
};
