import { Container, HeaderTitle, StyledBackLink } from './styles';
import React from 'react';
import { ArrowBackLink } from '../arrow-back-link';

interface ResourceBookingHeaderProps {
  withBackButton: boolean;
  title: string;
  onBackClick?: () => void;
}

export const ResourceBookingHeader = ({
  withBackButton,
  title,
  onBackClick,
}: ResourceBookingHeaderProps): JSX.Element => {
  return (
    <Container>
      {withBackButton && (
        <StyledBackLink>
          <ArrowBackLink onClick={onBackClick} />
        </StyledBackLink>
      )}
      <HeaderTitle>{title}</HeaderTitle>
    </Container>
  );
};
