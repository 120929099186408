import { LocationPicker } from 'components/location-picker';
import { useSelectLocationModal } from 'hooks/use-select-location-modal.hook';
import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { useIntl } from 'react-intl';
import {
  CheckboxOption,
  SelectLocationModalContent,
} from 'components/select-location-modal/select-location.modal-content';
import { SelectLocationModalFooter } from 'components/select-location-modal/select-location-modal-footer';
import { LocationPickerDesktop } from './desktop';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useCurrentLocation } from 'hooks/use-current-location.hook';
import { Alert } from 'components/alert';

interface LocationSelectorProps {
  isResourcesPage?: boolean;
}

export const LocationSelector = ({ isResourcesPage }: LocationSelectorProps): JSX.Element => {
  const {
    onLocationPickerClick,
    onModalClose,
    onButtonClick,
    onRadioButtonClick,
    options,
    selectedValue,
    onLocationsChange,
    selectAllChecked,
    onSelectAllChange,
  } = useSelectLocationModal();
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const locationName = useCurrentLocation();

  const noLocationSelected = useMemo(() => {
    if (options && options.length > 0 && options[0].hasOwnProperty('selected')) {
      return (options as CheckboxOption[]).every(({ selected }) => !selected);
    }
    return false;
  }, [options]);

  return (
    <>
      <LocationPicker locationName={locationName} onClick={onLocationPickerClick} isResourcesPage={isResourcesPage} />
      <Routes>
        <Route
          path="select-location-modal"
          element={
            isMobileDevice ? (
              <SwipeModal
                data-testid="select-location-modal"
                reverseAnimation={false}
                title={intl.formatMessage({ id: 'locations' })}
                className="select-location-modal"
                onClose={onModalClose}
                content={
                  <>
                    {noLocationSelected && <Alert testId="select_location_alert" label="please_select_1_building" />}
                    <SelectLocationModalContent
                      onRadioButtonClick={onRadioButtonClick}
                      onLocationsChange={onLocationsChange}
                      options={options}
                      selectedLocationName={selectedValue}
                      selectAllChecked={selectAllChecked}
                      onSelectAllChange={onSelectAllChange}
                    />
                  </>
                }
                footerContent={<SelectLocationModalFooter disabled={noLocationSelected} handleClick={onButtonClick} />}
              />
            ) : (
              <LocationPickerDesktop
                onClose={onModalClose}
                onButtonClick={onButtonClick}
                onRadioButtonClick={onRadioButtonClick}
                onLocationsChange={onLocationsChange}
                selectedLocationName={selectedValue}
                options={options}
                noLocationSelected={noLocationSelected}
                selectAllChecked={selectAllChecked}
                onSelectAllChange={onSelectAllChange}
              />
            )
          }
        />
      </Routes>
    </>
  );
};
