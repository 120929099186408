import { useLocale } from 'hooks/use-locale.hook';
import { useCurrencyType } from 'hooks/use-currency-type.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useSelector } from 'react-redux';
import { resourceState } from 'store/bookings/selectors';
import { selectPrice, selectPriceStatus } from 'store/price/selectors';
import { Price } from 'store/price/types';
import { ACTION_STATUSES } from 'shared/consts';
import { selectBuildingLocale } from 'store/building/selectors';
import { selectAllAddonsPrice } from 'store/add-ons/selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { searchParams } from 'pages/date-select-page/interface';
import { DEFAULT_BOTTOM_PADDING, DEFAULT_DATE_TIME_BLOCK_HEIGHT } from '../constants';

interface UseSelectedDateTimeFilterReturnValues {
  timeRange: { startTime: string; endTime: string; locale?: string };
  price: Price;
  minimumPrice: Price;
  startTime: string;
  endTime: string;
  priceStatus: ACTION_STATUSES;
  buildingLocale: string;
  showMinimumPrice: boolean;
  showFreeLabel: boolean;
  containerHeight: number;
  toggleMultipleBookingsVisibility: VoidFunction;
  isMultipleBookingsVisible: boolean;
  reverseAnimation: boolean;
}

export const useSelectedDateTimeFilter = (
  resourceContainerRef?: React.MutableRefObject<HTMLDivElement>,
): UseSelectedDateTimeFilterReturnValues => {
  const locale = useLocale();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const { startTime, endTime, startDates } = useSearchParams<searchParams>();
  const timeRange = { startTime: startTime as string, endTime: endTime as string, locale };
  const formattedStartDates = useMemo<Array<string>>(() => startDates?.split(',') || [], [startDates]);
  const { resource } = useSelector(resourceState);
  const resourcePrice = useSelector(selectPrice);
  const addOnsPrice = useSelector(selectAllAddonsPrice);
  const priceStatus = useSelector(selectPriceStatus);
  const minimumPrice = { price: resource.minimum_price, currency: useCurrencyType() } as Price;
  const isPaidResource = !!(
    resource?.minimum_price || resource?.booking_windows?.some(({ pricing_rules }) => pricing_rules?.length)
  );
  const showFreeLabel: boolean = resource?.show_free;
  const showMinimumPrice: boolean = !resourcePrice && isPaidResource && !!resource?.minimum_price;
  const price = {
    ...resourcePrice,
    price: resourcePrice?.price + addOnsPrice,
  };
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [isMultipleBookingsVisible, setIsMultipleBookingsVisible] = useState<boolean>(false);
  const [reverseAnimation, setReverseAnimation] = useState<boolean>(true);

  useEffect(() => {
    if (isMultipleBookingsVisible && formattedStartDates.length * DEFAULT_DATE_TIME_BLOCK_HEIGHT !== containerHeight) {
      setContainerHeight(formattedStartDates.length * DEFAULT_DATE_TIME_BLOCK_HEIGHT);
    }
  }, [isMultipleBookingsVisible, formattedStartDates, containerHeight]);

  const toggleMultipleBookingsVisibility = useCallback(() => {
    if (isMultipleBookingsVisible) {
      if (resourceContainerRef?.current) {
        resourceContainerRef.current.style.paddingBottom = `${DEFAULT_BOTTOM_PADDING}px`;
      }
      setReverseAnimation(true);

      setTimeout(() => {
        setIsMultipleBookingsVisible(false);
        setContainerHeight(0);
      }, 200);
    } else {
      if (resourceContainerRef?.current) {
        resourceContainerRef.current.style.paddingBottom = `${
          formattedStartDates.length * DEFAULT_DATE_TIME_BLOCK_HEIGHT + DEFAULT_BOTTOM_PADDING
        }px`;
      }
      setReverseAnimation(false);
      setContainerHeight(formattedStartDates.length * DEFAULT_DATE_TIME_BLOCK_HEIGHT);
      setIsMultipleBookingsVisible(true);
    }
  }, [formattedStartDates, isMultipleBookingsVisible, resourceContainerRef]);

  return {
    containerHeight,
    isMultipleBookingsVisible,
    toggleMultipleBookingsVisibility,
    timeRange,
    price,
    minimumPrice,
    startTime,
    endTime,
    priceStatus,
    buildingLocale,
    showMinimumPrice,
    showFreeLabel,
    reverseAnimation,
  };
};
