import React, { useCallback, useMemo } from 'react';
import { AvailableNow, Resource } from 'store/resource/types';
import { getCurrentBookingWindow } from 'utils/getCurrentBookingWindow';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';
import useResourcePaymentInfo from 'store/resource/hooks/use-resource-payment-info';
import useCrossBuildingBookingEnabled from 'store/features/hooks/use-cross-building-booking-enabled';
import useResourceBuildingLocationLabel from 'store/resource/hooks/use-resource-building-location-label';
import AvailableNowItem from 'components/available-now-block/components/available-now-item/available-now-item';
import { useIntl } from 'react-intl';
import { AvailableNowSubtitles, renderAvailabilitySubtitleText } from 'components/available-now-block/utils';

export interface AvailableNowItemContainerProps {
  resource: Resource;
  onClick: (
    resourceUuid: string,
    availableNow: AvailableNow,
    minimumDuration: number,
    maximumDuration: number,
    resourceType: string,
  ) => void;
}

const AvailableNowItemContainer: React.FC<AvailableNowItemContainerProps> = ({
  resource,
  onClick,
}: AvailableNowItemContainerProps): JSX.Element => {
  const intl = useIntl();
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const availableNowSubtitles = useMemo(() => {
    const values: AvailableNowSubtitles = {
      availableAllDay: intl.formatMessage({ id: 'available_all_day' }),
      availableFor: intl.formatMessage({ id: 'available_for' }),
      hours: intl.formatMessage({ id: 'hrs' }),
      minutes: intl.formatMessage({ id: 'min' }),
    };
    return values;
  }, [intl]);
  const { isPaid, isFree } = useResourcePaymentInfo(resource);
  const isCrossBuildingBookingEnabled = useCrossBuildingBookingEnabled();
  const locationLabel = useResourceBuildingLocationLabel(resource.building_uuid, { withCity: false });
  const onTileClick = useCallback(() => {
    const { currentBookingWindow } = getCurrentBookingWindow({
      bookingWindows: resource.booking_windows,
      date: resource.available_now.start_at,
    });
    onClick(
      resource.uuid,
      resource.available_now,
      currentBookingWindow?.minimum_duration,
      currentBookingWindow?.maximum_duration,
      resource.type,
    );
  }, [onClick, resource]);

  return (
    <AvailableNowItem
      name={resource.name}
      nowAvailabilitySummary={renderAvailabilitySubtitleText(resource.available_now, availableNowSubtitles)}
      compactUi={isCompactUiEnabled}
      imageUrl={resource.image_url}
      onClick={onTileClick}
      capacity={Number(resource.capacity)}
      isFree={isFree}
      isPaid={isPaid}
      locationLabel={isCrossBuildingBookingEnabled ? locationLabel : undefined}
    />
  );
};

export default AvailableNowItemContainer;
