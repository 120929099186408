import { TransactionsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { DisplayInfo, ItemType } from 'store/cart/types';
import { PATCH_STATUSES } from 'shared/consts';

export const transactionsSelector = (state: RootState): TransactionsState => state.transactions;

export const selectTransactions = createSelector([transactionsSelector], data => data.transactions);

export const selectTransactionsLoading = createSelector([transactionsSelector], data => data.loading);

export const selectTransactionsError = createSelector([transactionsSelector], data => data.error);

export const selectTransaction = createSelector([transactionsSelector], transactions => transactions.transaction);

export const selectTransactionStatus = createSelector(
  [transactionsSelector],
  transactions => transactions.getTransaction.status,
);

export const selectTransactionError = createSelector(
  [transactionsSelector],
  transactions => transactions.getTransaction.error,
);

export const selectPatchTransactionsStatus = createSelector(
  [transactionsSelector],
  data => data?.patchTransaction?.status,
);

export const selectTypeOfPatchTransaction = createSelector(
  [transactionsSelector],
  data => data?.patchTransaction?.type,
);

export const selectIs3dsPatchTransaction = createSelector(
  [transactionsSelector],
  data => data.patchTransaction.type !== PATCH_STATUSES.CANCELLED,
);

export const selectRedirectTransaction = createSelector(
  [transactionsSelector],
  transactions => transactions.redirectTransaction,
);

export const selectGetRedirectTransactionStatus = createSelector(
  [transactionsSelector],
  transactions => transactions.getRedirectTransaction.status,
);

export const selectPatchTransactionsError = createSelector([transactionsSelector], data => data.patchTransaction.error);

export const selectTransactionDisplay = createSelector(selectTransaction, transaction => {
  return {
    ...transaction,
    total_summary: {
      currency_type: transaction?.currency_type,
      discount_applied: transaction?.total_discount,
      subtotal: transaction?.total_price,
      tax_summary: {
        taxes_total: transaction?.total_tax,
      },
      total: transaction?.total_charge,
    },
    items: [
      {
        display_info: {
          title: '',
          description1: '',
        } as DisplayInfo,
        id: '',
        type: ItemType.RESOURCE_BOOKING,
        quantity: 1,
      },
    ],
  };
});

export const selectCurrentTransaction = createSelector([transactionsSelector], data => data.currentTransaction);
